import * as React from "react"

export default function TableRow({row}) {
    return (
        <article className="card card-custom">
            <div className="card-standing-position card-standing-counter"/>
            <div className="card-header">
                <div className="card-standing-team-item">
                    <div className="card-standing-team">
                        <div className="card-standing-team-figure">
                            <img src={row.teamLogo} alt="" height="36"/>
                        </div>
                        <div className="card-standing-team-title">
                            <div className="card-standing-team-name">{row.teamName}</div>
                        </div>
                    </div>
                    <div className="card-standing-number">{row.m}</div>
                    <div className="card-standing-number">{row.p3}</div>
                    <div className="card-standing-number">{row.p2}</div>
                    <div className="card-standing-points">{row.p1}</div>
                    <div className="card-standing-points">{row.p0}</div>
                    <div className="card-standing-points d-none d-md-block">{row.plus}</div>
                    <div className="card-standing-points d-none d-md-block">{row.minus}</div>
                    <div className="card-standing-points d-none d-md-block">{row.gk}</div>
                    <div className="card-standing-diff">{row.p}</div>
                </div>
            </div>
        </article>
    )
}


import React from "react"
import Moment from 'react-moment';
import {Link} from "gatsby";

export default function Result({game}) {
    console.log(game)
    return (
        <article className="game-result game-result-modern">
            {game.homeTeamName === 'x' || game.awayTeamName === 'x' ?
                <Link to={`/merkozes/#${game.id}`} target={'_blank'} rel={'noreferrer'}>
                    <div className="badge badge-primary">{game.championshipName}</div>
                    <div className="game-result-footer">
                        <ul className="game-result-details">

                            {game.gameStatus === 1 && <><div className="badge badge-red">ÉLŐ</div> <br/></>}
                            <li>{game.location}<br/>
                                <time dateTime={game.gameDate}>
                                    <Moment format="YYYY-MM-DD HH:mm">
                                        {game.gameDate}
                                    </Moment>
                                </time>
                            </li>
                        </ul>
                    </div>
                </Link>
                :

                <Link to={`/merkozes/#${game.id}`} target={'_blank'} rel={'noreferrer'}>
                <div className="badge badge-primary">{game.championshipName}</div>
                    <div className="game-result-main">
                        <div className="game-result-team game-result-team-first game-result-team-win">
                            <span
                                className="game-result-team-label game-result-team-label-top">
                                <img src={game.homeTeamLogo} alt={game.homeTeamName}/>
                            </span>
                            <div className="game-result-team-name">{game.homeTeamName}</div>
                        </div>
                        {game.gameStatus !== 0 &&
                        <div className="game-result-score-wrap">
                            <div className="game-result-score">{game.homeTeamScore}</div>

                            <div className="game-result-score-divider">
                                <svg x="0px" y="0px" width="7px" height="21px" viewBox="0 0 7 21"
                                     enableBackground="new 0 0 7 21">
                                    <g>
                                        <circle cx="3.5" cy="3.5" r="3"/>
                                        <path d="M3.5,1C4.879,1,6,2.122,6,3.5S4.879,6,3.5,6S1,4.878,1,3.5S2.122,1,3.5,1 M3.5,0C1.567,0,0,1.567,0,3.5S1.567,7,3.5,7      S7,5.433,7,3.5S5.433,0,3.5,0L3.5,0z"/>
                                    </g>
                                    <g>
                                        <circle cx="3.695" cy="17.5" r="3"/>
                                        <path d="M3.695,15c1.378,0,2.5,1.122,2.5,2.5S5.073,20,3.695,20s-2.5-1.122-2.5-2.5S2.316,15,3.695,15 M3.695,14      c-1.933,0-3.5,1.567-3.5,3.5s1.567,3.5,3.5,3.5s3.5-1.567,3.5-3.5S5.628,14,3.695,14L3.695,14z"/>
                                    </g>
                                </svg>
                            </div>
                            <div className="game-result-score">{game.awayTeamScore}</div>
                        </div>}
                        <div className="game-result-team game-result-team-second">
                            <span
                                className="game-result-team-label game-result-team-label-top">
                                <img src={game.awayTeamLogo} alt={game.awayTeamName}/>
                            </span>
                            <div className="game-result-team-name">{game.awayTeamName}</div>
                        </div>
                    </div>
                    <div className="game-result-footer">
                        <ul className="game-result-details">

                            {game.gameStatus === 1 && <><div className="badge badge-red">ÉLŐ</div> <br/></>}
                            <li>{game.location}<br/>
                                <time dateTime={game.gameDate}>
                                    <Moment format="YYYY-MM-DD HH:mm">
                                        {game.gameDate}
                                    </Moment>
                                </time>
                            </li>
                        </ul>
                    </div>
                </Link>}
        </article>
    )
}


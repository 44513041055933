import React from "react"
import {Link} from "gatsby";

export default function LatestNews({news, title, bottomLimit}) {
    return (
        <div className="aside-component" id={'hirek'}>

            <article className="heading-component">
                <div className="heading-component-inner">
                    <h5 className="heading-component-title">{title || 'További híreink'}</h5>
                    <Link className="button button-xs button-gray-outline" to={'/hirek'}>Minden hír</Link>
                </div>
            </article>


            <div className="list-post-classic">

                {news.map(function(post,index){
                    return index >= bottomLimit && <article className="post-classic">
                        <div className="post-classic-aside">
                            <Link to={`/hirek/${post.slug}`} className="post-classic-figure">
                                <img src={post.thumbnail.url} alt="" width="94"/>
                            </Link>
                        </div>
                        <div className="post-classic-main">
                            <p className="post-classic-title">
                                <Link to={`/hirek/${post.slug}`}>{post.title}</Link>
                            </p>
                            {post.label && post.label.map(function(label,labelIndex){
                                return <div key={labelIndex} className="badge badge-primary">{label}</div>
                            })}
                            <div className="post-classic-time">
                                <span className="icon mdi mdi-clock"/>
                                <time dateTime={post.publishedAt}>{post.publishedAt}</time>
                            </div>
                        </div>
                    </article>
                })}
            </div>
        </div>
    )
}

import * as React from "react"
import noimage from "../../images/noimage.png";
import {useEffect, useState} from "react";

export default function GoaliesWithStat({player,allPlayers}) {
    const [image,setImage] = useState(noimage);

    useEffect(()=>{
        allPlayers.map(function (onePlayer){
            if(onePlayer.image !== null && onePlayer.playerId == player.id){
                setImage(onePlayer.image.url)
                return onePlayer.image.url
            }else{
                return noimage;
            }
        })
    },[])

    return (
        <div className="player-info-corporate player-info-other-team">
            <div className="player-info-figure">
                <div className="player-img">
                    <img src={image ? image : noimage} alt="" width="300"/>
                </div>
            </div>
            <div className="player-info-main">
                <h4 className="player-info-title">{player.lastName} {player.firstName}</h4>
                {player.additional && <p className="player-info-subtitle"># {player.additional.jerseyNr}</p>}
                <hr/>
                <div className="player-info-table">
                    <div className="table-custom-wrap">
                        <table className="table-custom">
                            <tbody>
                            <tr>
                                <th>Mérkőzések</th>
                                <th>{player.gpi}</th>
                            </tr>
                            <tr>
                                <td>Kapott lövések</td>
                                <td>{player.sog}</td>
                                <td>Védett lövések</td>
                                <td>{player.svs}</td>
                            </tr>
                            <tr>
                                <td>Azonosító</td>
                                <td>{player.id}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mt-3">
                    {player.svsPercent !== 0 &&
                    <article className="progress-linear progress-bar-modern animated">
                        <div className="progress-header">
                            <p>Védési hatékonyság</p>
                        </div>
                        <div className="progress-bar-linear-wrap">
                            <div className="progress-bar-linear" style={{transitionDuration: '1s', width: player.svsPercent+'%'}}/>
                        </div>
                        <span className="progress-value">{player.svsPercent}</span>
                    </article>
                    }
                </div>
            </div>
        </div>
    )
}


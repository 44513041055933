import React, {useEffect, useState} from 'react';
import Layout from "../components/layout";
import noimage from "../images/noimage.png";
import {graphql, Link} from "gatsby";
import TableRow from "../components/elements/tableRow";
import axios from "axios";
import TeamMemberWithStat from "../components/elements/teamMemberWithStat";
import GoaliesWithStat from "../components/elements/goaliesWithStat";
import LatestNews from "../components/latest-news";
import NextGames from "../components/elements/nextGames";
import {Helmet} from "react-helmet";
import PrevGames from "../components/elements/prevGames";
import Footer from "../components/footer";
import Header from "../components/header";

function TeamSingle({data}) {

    const partners = data.allContentfulPartners.nodes;
    const teams = data.allContentfulTeam.nodes
    const pages = data.allContentfulPages.nodes

    const [standings, setStangings] = useState(false)
    const [players, setPlayers] = useState(false)
    const [playerInfo, setPlayerInfo] = useState(false)
    const [goaliesInfo, setGoaliesInfo] = useState(false)
    const [additionalInfo, setAdditionalInfo] = useState(false)
    const [goalies, setGoalies] = useState(false)
    const [games, setGames] = useState(false)

    const team = data.contentfulTeam;
    const news = data.allContentfulBlogPost.nodes;
    const allPlayers = data.allContentfulPlayer.nodes;

    function getStandings(){
        const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
        const API = 'https://api.icehockey.hu/vbr/v1';

        const championshipId = team.championshipId;
        const division = team.divisionNameForStandings;

        axios.get(API+'/standings', {
            params: {
                championshipId:championshipId,
                division:division
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': APIKEY,
            },
        }).then((response) =>{
            setStangings(response.data.data);
        }).catch((error) => {
            console.error(error)
        });
    }

    async function getTeamPlayers(){
        const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
        const API = 'https://api.icehockey.hu/vbr/v1';

        const championshipId = team.championshipId;
        const teamId = team.teamId;
        const division = team.divisionNameForStandings;

        try {
            axios.get(API+'/teamPlayersStatsChampionship', {
                params: {
                    championshipId:championshipId,
                    teamId:teamId,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': APIKEY,
                },
            }).then((response) =>{
                setPlayerInfo(response.data.data);
            });
        } catch (error) {
            console.error(error);
        }
    }

    async function getGames(){
        const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
        const API = 'https://api.icehockey.hu/vbr/v1';

        const championshipId = team.championshipId;
        const teamId = team.teamId;
        const division = team.divisionNameForStandings;

        try {
            axios.get(API+'/championshipCalendar', {
                params: {
                    championshipId:championshipId
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': APIKEY,
                },
            }).then((response) =>{
                setGames(response.data.data);
            });
        } catch (error) {
            console.error(error);
        }
    }

    async function getGoalies(){
        const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
        const API = 'https://api.icehockey.hu/vbr/v1';

        const championshipId = team.championshipId;
        const teamId = team.teamId;
        const division = team.divisionNameForStandings;

        try {
            axios.get(API+'/teamGoalieStatsChampionship', {
                params: {
                    championshipId:championshipId,
                    teamId:teamId,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': APIKEY,
                },
            }).then((response) =>{
                setGoaliesInfo(response.data.data);
            });
        } catch (error) {
            console.error(error);
        }
    }

    async function getAdditionalInfo(){
        const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
        const API = 'https://api.icehockey.hu/vbr/v1';

        const championshipId = team.championshipId;
        const teamId = team.teamId;
        const division = team.divisionNameForStandings;

        try {
            axios.get(API+'/teamPlayers', {
                params: {
                    championshipId:championshipId,
                    teamId:teamId,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': APIKEY,
                },
            }).then((response) =>{
                setAdditionalInfo(response.data.data.null);
            });
        } catch (error) {
            console.error(error);
        }
    }

    async function mergePlayers(arr1,arr2){
        try {
            let res = [];
            res = arr1.map(obj => {
                const index = arr2.findIndex(el => el["id"] == obj["id"]);
                return {
                    ...obj,
                    additional: arr2[index]
                };
            });
            setPlayers(res);
            return res;
        } catch (e) {
            console.error(e)
        }
    }

    async function mergeGoalies(arr1,arr2){
        try {
            let res = [];
            res = arr1.map(obj => {
                const index = arr2.findIndex(el => el["id"] == obj["id"]);
                return {
                    ...obj,
                    additional: arr2[index]
                };
            });
            setGoalies(res);
            return res;
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(()=>{
        getStandings()
        getGames()
        getTeamPlayers()
        getGoalies()
        getAdditionalInfo()
    },[])

    useEffect(()=>{
        mergePlayers(playerInfo,additionalInfo)
        mergeGoalies(goaliesInfo,additionalInfo)
    },[playerInfo,goaliesInfo,additionalInfo])

    return (
        <Layout>
            <Helmet>
                <title>{team.title} - Zempléni Hiúzok</title>
            </Helmet>
            <Header pages={pages} teams={teams}/>
            <main>
                {team.image &&
                    <section className="section parallax-container breadcrumbs-wrap">
                        <div className="material-parallax parallax">
                            <img src={team.image ? team.image.url : noimage} alt="" />
                        </div>
                        <div className="parallax-content breadcrumbs-custom context-dark">
                            <div className="container">
                                <h3 className="breadcrumbs-custom-title">{team.title}</h3>
                                <ul className="breadcrumbs-custom-path">
                                    <li><Link to="/">Főoldal</Link></li>
                                    <li><Link to="/csapataink">Csapataink</Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                }

                {!team.image &&
                    <section className="section section-md bg-gray-100 text-center">
                        <div className="container">
                            <h3 className="breadcrumbs-custom-title">{team.title}</h3>
                        </div>
                    </section>
                }
                <div className={'section section-md bg-gray-100'}>,
                    <div className={'container'}>
                        <div className={'row row-50'}>
                            <div className={'col-12 d-lg-none'}>
                                <a href={'#tabella'} className="button button-block button-secondary">Tabella</a>
                                <a href={'#merkozesek'} className="button button-block button-secondary">Mérkőzések</a>
                                {news.length !==0 && <a href={'#hirek'} className="button button-block button-secondary">Hírek</a>}
                                <a href={'#jatekosok'} className="button button-block button-secondary">Játékosok</a>
                            </div>

                            <div className={'col-lg-8 pe-lg-4'}>
                                <article className="heading-component" id={'jatekosok'}>
                                    <div className="heading-component-inner">
                                        <h5 className="heading-component-title">Kapusok</h5>
                                    </div>
                                </article>

                                <div className="row row-50">
                                    <div className="col-xl-12">
                                        <div className="row row-30">
                                            {goalies && goalies.map(function(player,index){
                                                return <div className="col-sm-12">
                                                    <GoaliesWithStat key={index} player={player} allPlayers={allPlayers}/>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <article className="heading-component mt-5">
                                    <div className="heading-component-inner">
                                        <h5 className="heading-component-title">Mezőnyjátékosok</h5>
                                    </div>
                                </article>

                                <div className="row row-50">
                                    <div className="col-xl-12">
                                        <div className="row row-30">
                                            {players && players.map(function(player,index){
                                                return <div className="col-sm-12">
                                                    <TeamMemberWithStat key={index} player={player} allPlayers={allPlayers}/>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-lg-4 ps-lg-5'}>
                                {news.length !==0 && <LatestNews news={news} title={'Hírek'} bottomLimit={0}/>}

                                {games && <NextGames teamId={team.teamId} games={games}/>}
                                {games && <PrevGames teamId={team.teamId} games={games}/>}

                                <a href={'#tabella'} className="button button-block button-primary d-none d-lg-block">Tabella</a>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="section section-md bg-gray-300" id={'tabella'}>
                    <div className="container">

                        <div className="main-component">

                            <article className="heading-component">
                                <div className="heading-component-inner">
                                    <h5 className="heading-component-title">Tabella</h5>
                                </div>
                            </article>

                            <div className="card-standing card-group-custom card-standing-index" id="accordion1"
                                 role="tablist" aria-multiselectable="false">
                                <div className="card-standing-caption">
                                    <div className="card-standing-position">#</div>
                                    <div className="card-standing-caption-aside">
                                        <div className="card-standing-team">Csapat</div>
                                        <div className="card-standing-number">M</div>
                                        <div className="card-standing-number">GY</div>
                                        <div className="card-standing-number">GYH</div>
                                        <div className="card-standing-points">VH</div>
                                        <div className="card-standing-diff">V</div>
                                        <div className="card-standing-diff d-none d-md-block">SZG</div>
                                        <div className="card-standing-diff d-none d-md-block">KG</div>
                                        <div className="card-standing-diff d-none d-md-block">GK</div>
                                        <div className="card-standing-diff">P</div>
                                    </div>
                                </div>

                                {standings && standings.map(function(row,index){
                                    return row.teamName !== 'x' && <TableRow key={index} row={row}/>
                                })}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer partners={partners}/>
        </Layout>
    );
}

export default TeamSingle;

export const query = graphql`
  query TeamBySlug(
    $slug: String!
  ) {
    contentfulTeam(label: { eq: $slug }) {
      id
      teamId
      title
      image{
        url
        }
      championshipId
      divisionNameForStandings
    }
    allContentfulPlayer {
      nodes {
        image {
          url
        }
        playerId
      }
    }
    allContentfulBlogPost(
        filter: {label: {eq: $slug}}
        limit: 5
        sort: {fields: publishedAt, order: DESC}
    ) {
        nodes {
          title
          thumbnail {
            url
          }
          slug
          previewText
          publishedAt
          label
        }
      }
      allContentfulPartners {
        nodes {
          title
          logo {
            url
          }
        }
      }
      allContentfulPages {
        nodes {
          text {
            raw
          }
          title
          slug
        }
      }
      allContentfulTeam(sort: {fields: title, order: ASC}) {
        nodes {
          title
          label
        }
      }
  }
`

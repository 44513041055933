import * as React from "react"
import noimage from "../../images/noimage.png";
import {useEffect, useState} from "react";

export default function TeamMemberWithStat({player, allPlayers}) {
    const [image,setImage] = useState(noimage);

    useEffect(()=>{
        allPlayers.map(function (onePlayer){
            if(onePlayer.image !== null && onePlayer.playerId == player.id){
                setImage(onePlayer.image.url)
                return onePlayer.image.url
            }else{
                return noimage;
            }
        })
    },[])

    return (
        <div className="player-info-corporate player-info-other-team">
            <div className="player-info-figure">
                <div className="player-img">
                    <img src={image} alt="" width="300"/>
                </div>
            </div>
            <div className="player-info-main">
                <h4 className="player-info-title">{player.lastName} {player.firstName}</h4>
                <p className="player-info-subtitle"># {player.additional &&player.additional.jerseyNr}</p>
                <hr/>
                    <div className="player-info-table">
                        <div className="table-custom-wrap">
                            <table className="table-custom">
                                <tbody>
                                <tr>
                                    <th>Mérkőzések</th>
                                    <th>{player.gp}</th>
                                    <th>Pontok</th>
                                    <th>{player.point}</th>
                                </tr>
                                <tr>
                                    <th>Gólok</th>
                                    <th>{player.g}</th>
                                    <th>Gólpassz</th>
                                    <th>{player.a}</th>
                                </tr>
                                <tr>
                                    <td>Lövések</td>
                                    <td>{player.shoot}</td>
                                    <td>+/-</td>
                                    <td>{player.plusMinus}</td>
                                </tr>
                                <tr>
                                    <td>Böntetések</td>
                                    <td>{player.pim || 0} perc</td>
                                </tr>
                                <tr>
                                    <td>Azonosító</td>
                                    <td>{player.id}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="mt-3">
                        {player.shootPercent !== 0 &&
                            <article className="progress-linear progress-bar-modern animated">
                                <div className="progress-header">
                                    <p>Lövési hatékonyság</p>
                                </div>
                                <div className="progress-bar-linear-wrap">
                                    <div className="progress-bar-linear" style={{transitionDuration: '1s', width: player.shootPercent+'%'}}/>
                                </div>
                                <span className="progress-value">{player.shootPercent}</span>
                            </article>
                        }
                    </div>
            </div>
        </div>
    )
}


import React from "react"
import Result from "../result";

export default function PrevGames({games, teamId}) {

    return (
        <div className="aside-component" id={'merkozesek'}>

            <article className="heading-component mt-5">
                <div className="heading-component-inner">
                    <h5 className="heading-component-title">Előző mérkőzések</h5>
                </div>
            </article>

            {games.map(function(game,index){
                if((game.homeTeamId == teamId || game.awayTeamId == teamId) && game.gameStatus == 2){
                    return <Result key={index} game={game}/>
                }
            })}
        </div>
    )
}

